<template>
    <el-card class="menu-tree-card" shadow="never">
        <el-tree
            ref="menu-tree"
            node-key="id"
            :data="menus"
            :props="defaultProps"
            @current-change="currentChange"
            :expand-on-click-node="false"
            :highlight-current="true"
            default-expand-all
            :key="key"
        >
        </el-tree>
    </el-card>
</template>
<script>
import { getMenusApi } from "@/api/system/menu";
import { genMenuChildren } from "@/utils/menu";

export default {
    name: "MenuTree",
    computed: {
        menus() {
            let menus = genMenuChildren(
                this.allMenus.filter(item => item.type == 0)
            );
            let res = [
                {
                    id: -1,
                    menu: "Menu",
                    menu_name: "菜单",
                    children: menus.filter(item => {
                        return item.parent_id == -1 && item.type == 0;
                    })
                }
            ];

            this.$emit("callBackMenuAll", res);
            return res;
        }
    },
    data() {
        return {
            key: 0,
            allMenus: [],
            defaultProps: {
                label: "menu_name",
                children: "children"
            }
        };
    },
    methods: {
        async getAllMenu() {
            return new Promise((resolve, reject) => {
                getMenusApi({})
                    .then(res => {
                        if (res.data.code == 0) {
                            this.$set(this, "allMenus", res.data.data);
                            resolve(res.data.data);
                        } else {
                            this.$notify.error({
                                title: "失败",
                                message: res.data.msg
                            });
                            reject();
                        }
                    })
                    .catch(error => {
                        this.$notify.error({
                            title: "异常",
                            message: error
                        });
                        reject();
                    });
            });
        },
        async refreshMenus() {
            await this.getAllMenu();
            let tree = this.$refs["menu-tree"];
            let currentKey = tree.getCurrentKey();
            if (currentKey) {
                tree.setCurrentKey(currentKey);
            } else {
                tree.setCurrentKey(-1);
                this.currentChange(tree.getCurrentNode());
            }
        },
        currentChange(data) {
            this.$emit("currentChange", data);
        }
    },
    created() {
        this.refreshMenus();
    }
};
</script>
<style lang="scss" scoped>
.menu-tree-card {
    overflow: auto;
}
</style>
