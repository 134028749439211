<template>
    <el-dialog
        class="menu-dialog"
        :title="title"
        :visible.sync="isShow"
        width="600px"
        @open="handlerOpen"
        @closed="handlerReset"
        :close-on-click-modal="false"
    >
        <el-form
            ref="menuInfo"
            class="menu-form"
            :model="form"
            label-width="80px"
            size="mini"
            :rules="rules"
            :key="key"
        >
            <el-form-item label="菜单标识" prop="menu">
                <el-input v-if="isAdd" v-model="form.menu"></el-input>
                <span v-else>{{ form.menu || "-" }}</span>
            </el-form-item>
            <el-form-item label="菜单名称" prop="menu_name">
                <el-input
                    v-if="isAdd || isEdit"
                    v-model="form.menu_name"
                ></el-input>
                <span v-else>{{ form.menu_name || "-" }}</span>
            </el-form-item>
            <el-form-item label="上级菜单" prop="parent_id" size="small">
                <el-cascader
                    v-if="isAdd || isEdit"
                    v-model="form.parent_id"
                    placeholder="试试搜索：首页"
                    :options="menusAll"
                    filterable
                    change-on-select
                    :props="menuCascaderProps"
                ></el-cascader>
                <span v-else-if="isShow">{{
                    menuPathNameGetter(form.parent_id)
                }}</span>
            </el-form-item>
            <el-form-item label="菜单类型" prop="type">
                <el-radio-group v-if="isAdd || isEdit" v-model="form.type">
                    <el-radio label="0">菜单</el-radio>
                    <el-radio label="1">权限</el-radio>
                </el-radio-group>
                <span v-else>{{
                    form.type == "0" ? "菜单" : "权限" || "-"
                }}</span>
            </el-form-item>
            <el-form-item label="排序" prop="sort">
                <el-input-number
                    v-if="isAdd || isEdit"
                    v-model="form.sort"
                    :min="1"
                    :max="999"
                ></el-input-number>
                <span v-else>{{ form.sort || "-" }}</span>
            </el-form-item>
            <el-form-item label="图标标识" prop="icon" v-show="form.type == 0">
                <el-input
                    v-if="isAdd || isEdit"
                    v-model="form.icon"
                    :readonly="true"
                    placeholder="请点击右侧按钮选择图标"
                >
                    <el-button slot="prepend" size="mini">
                        <i v-if="form.icon" :class="form.icon"></i>
                    </el-button>
                    <el-button
                        slot="append"
                        size="mini"
                        @click="dialog.icon.visible = true"
                    >
                        <i class="el-icon-plus"></i>
                    </el-button>
                </el-input>
                <span v-else>
                    <i v-if="form.icon" :class="form.icon"> </i>
                </span>
            </el-form-item>
            <el-form-item label="地址" prop="path" v-show="form.type == 0">
                <el-input v-if="isAdd || isEdit" v-model="form.path"></el-input>
                <span v-else>{{ form.path || "-" }}</span>
            </el-form-item>
            <el-form-item label="组件" prop="component" v-show="form.type == 0">
                <el-input
                    v-if="isAdd || isEdit"
                    v-model="form.component"
                ></el-input>
                <span v-else>{{ form.component || "-" }}</span>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button size="mini" @click="handlerClose">关闭</el-button>
            <el-button size="mini" v-if="isAdd || isEdit" @click="handlerReset"
                >重置</el-button
            >
            <el-button
                type="primary"
                size="mini"
                v-if="isAdd || isEdit"
                @click="handlerSave"
                >保存</el-button
            >
        </span>
        <IconSelectDialog
            :visible.sync="dialog.icon.visible"
            @change-select="handlerIconSelected"
        />
    </el-dialog>
</template>
<script>
import { addMenuApi, updateMenuApi } from "@/api/system/menu.js";
import IconSelectDialog from "./iconSelectDialog.vue";
import { findMenuPathName } from "@/utils/menu";

export default {
    name: "MenuDialog",
    components: {
        IconSelectDialog
    },
    props: {
        title: {
            type: String,
            default: () => ""
        },
        visible: {
            type: Boolean,
            required: true
        },
        type: {
            type: String
        },
        data: {
            type: Object
        },
        menusAll: {
            type: Array,
            default: () => []
        }
    },
    computed: {
        isShow: {
            get() {
                return this.visible;
            },
            set(v) {
                this.$emit("update:visible", v);
            }
        },
        isAdd() {
            return this.type === "add";
        },
        isEdit() {
            return this.type === "update";
        },
        rules() {
            let {
                menu,
                menu_name,
                parent_id,
                sort,
                type,
                path,
                component
            } = this.rulesAll;
            let rule = {};
            if (this.isAdd) {
                rule = Object.assign(rule, {
                    menu,
                    menu_name,
                    parent_id,
                    sort,
                    type
                });
                if (this.form.type === "0") {
                    rule = Object.assign(rule, {
                        path,
                        component
                    });
                }
            } else if (this.isEdit) {
                rule = Object.assign(rule, {
                    menu_name,
                    parent_id,
                    sort,
                    type
                });
                if (this.form.type === "0") {
                    rule = Object.assign(rule, {
                        path,
                        component
                    });
                }
            } else {
                rule = {};
            }
            return rule;
        },
        reqParams() {
            let res = Object.assign({}, this.form);
            return res;
        }
    },
    data() {
        return {
            key: 0,
            form: {},
            rulesAll: {
                menu: [
                    {
                        required: true,
                        message: "请输入菜单标识",
                        trigger: "blur"
                    },
                    {
                        min: 3,
                        max: 20,
                        message: "长度在 3 到 20 个字符",
                        trigger: "blur"
                    }
                ],
                menu_name: [
                    {
                        required: true,
                        message: "请输入菜单名称",
                        trigger: "blur"
                    },
                    {
                        max: 20,
                        message: "长度不能超过 20 个字符",
                        trigger: "blur"
                    }
                ],
                parent_id: [
                    {
                        required: true,
                        message: "请选择上级菜单",
                        trigger: "blur"
                    }
                ],
                sort: [
                    {
                        required: true,
                        message: "请输入排序",
                        trigger: "blur"
                    }
                ],
                type: [
                    {
                        required: true,
                        message: "请选择菜单类型",
                        trigger: "blur"
                    }
                ],
                path: [
                    {
                        required: true,
                        message: "请输入地址",
                        trigger: "blur"
                    },
                    {
                        max: 255,
                        message: "长度不能超过 255 个字符",
                        trigger: "blur"
                    }
                ],
                component: [
                    {
                        required: true,
                        message: "请输入组件",
                        trigger: "blur"
                    },
                    {
                        max: 255,
                        message: "长度不能超过 255 个字符",
                        trigger: "blur"
                    }
                ]
            },
            dialog: {
                icon: {
                    visible: false,
                    selectedIcon: ""
                }
            },
            menuCascaderProps: {
                label: "menu_name",
                value: "id",
                children: "children",
                emitPath: false
            }
        };
    },
    methods: {
        setData() {
            if (this.isAdd) {
                this.$set(this, "form", {
                    menu: "",
                    menu_name: "",
                    parent_id: "",
                    sort: 99,
                    icon: "",
                    type: "0",
                    path: "",
                    component: ""
                });
            } else {
                this.$set(this, "form", Object.assign({}, this.data));
            }
        },
        handlerReset() {
            this.$refs["menuInfo"].resetFields();
        },
        handlerOpen() {
            this.key++;
            this.setData();
        },
        handlerClose() {
            this.isShow = false;
        },
        handlerSave() {
            this.$refs["menuInfo"].validate(valid => {
                if (valid) {
                    let action = undefined;
                    if (this.isAdd) {
                        action = addMenuApi;
                    } else if (this.isEdit) {
                        action = updateMenuApi;
                    } else {
                        return false;
                    }
                    action(this.reqParams)
                        .then(res => {
                            if (res.data.code == 0) {
                                this.$notify({
                                    title: "成功",
                                    message: "保存成功",
                                    type: "success"
                                });
                                this.handlerClose();
                                this.$emit("reload");
                            } else {
                                this.$notify.error({
                                    title: "失败",
                                    message: res.data.msg || "保存失败"
                                });
                            }
                        })
                        .catch(error => {
                            this.$notify.error({
                                title: "异常",
                                message: error
                            });
                        });
                } else {
                    return false;
                }
            });
        },
        handlerIconSelected(icon) {
            this.$set(this.form, "icon", icon);
        },
        menuPathNameGetter(v) {
            let menus = findMenuPathName(this.menusAll, v);
            return Array.isArray(menus) ? menus.join(" / ") : "-";
        }
    }
};
</script>
<style lang="scss" scoped>
.el-form .el-form-item {
    .el-cascader {
        width: 100%;
    }
}
</style>
