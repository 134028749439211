<template>
    <el-container class="view-menu">
        <el-aside class="view-menu-aside" width="200px"
            ><MenuTree
                class="menu-tree"
                ref="menu-tree"
                @currentChange="handlerSelectMenuTree"
                @callBackMenuAll="callBackMenuAll"
            />
        </el-aside>
        <el-main class="view-menu-main">
            <el-form
                ref="menuForm"
                :inline="true"
                :model="form"
                class="form-inline"
                label-width="80px"
                size="mini"
                label-position="left"
            >
                <el-row>
                    <el-form-item label="菜单标识" prop="menu">
                        <el-input
                            v-model="form.menu"
                            placeholder="菜单标识"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="菜单名称" prop="menu_name">
                        <el-input
                            v-model="form.menu_name"
                            placeholder="菜单名称"
                        ></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="queryTable"
                            >查询</el-button
                        >
                        <el-button
                            @click="resetForm"
                            icon="el-icon-refresh-right"
                        ></el-button>
                    </el-form-item>
                </el-row>
            </el-form>
            <div class="button-ctrl">
                <el-button
                    type="success"
                    @click="showMenuDialog('add')"
                    icon="el-icon-plus"
                    size="mini"
                    >新增</el-button
                >
            </div>
            <TableList
                class="menu-table"
                ref="menuTable"
                :query="form"
                :columns="table.columms"
                :tableData.sync="table.tableData"
                :page.sync="table.page"
                :doHandler="table.doHandler"
                fullHeight
                border
            >
                <template slot="icon" slot-scope="{ value }">
                    <i v-if="value" :class="value"></i>
                    <span v-else>-</span>
                </template>
                <template slot="type" slot-scope="{ value }">
                    {{ value == 0 ? "菜单" : "权限" }}
                </template>
                <template slot="action" slot-scope="{ data }">
                    <div class="column-scope-action">
                        <el-link
                            type="primary"
                            icon="el-icon-edit"
                            @click="showMenuDialog('update', data)"
                            >编辑</el-link
                        >
                        <el-link
                            type="info"
                            icon="el-icon-view"
                            @click="showMenuDialog('detail', data)"
                            >详情</el-link
                        >
                        <el-link
                            type="danger"
                            icon="el-icon-delete"
                            @click="deleteMenu(data)"
                            >删除</el-link
                        >
                    </div>
                </template>
            </TableList>
        </el-main>
        <MenuDialog
            :visible.sync="dialog.menuInfo.visible"
            :title="dialog.menuInfo.title"
            :type="dialog.menuInfo.type"
            :data="dialog.menuInfo.data"
            :menusAll="menusAll"
            @reload="refresHandler"
        />
    </el-container>
</template>
<script>
import { getMenuPageApi, deleteMenuApi } from "@/api/system/menu.js";
import TableList from "@/components/table-list";
import MenuTree from "./menuTree";
import MenuDialog from "./menuDialog";
import columms from "./columns";

export default {
    name: "Menu",
    components: {
        TableList,
        MenuTree,
        MenuDialog
    },
    data() {
        return {
            form: {
                parent_id: -1,
                menu: "",
                menu_name: ""
            },
            table: {
                columms,
                page: {
                    page: 1,
                    pageSize: 10,
                    total: 0
                },
                doHandler: getMenuPageApi,
                tableData: []
            },
            dialog: {
                menuInfo: {
                    visible: false,
                    type: "",
                    title: "",
                    data: undefined
                }
            },
            menusAll: []
        };
    },
    methods: {
        callBackMenuAll(menusAll) {
            this.menusAll = menusAll;
        },
        async callDeleteMenuApi(data) {
            return new Promise((resolve, reject) => {
                deleteMenuApi(data)
                    .then(() => {
                        this.$notify({
                            title: "成功",
                            message: "删除成功",
                            type: "success"
                        });
                        resolve();
                    })
                    .catch(error => {
                        this.$notify.error({
                            title: "异常",
                            message: error
                        });
                        reject();
                    })
                    .finally(() => {
                        this.queryTable();
                    });
            });
        },
        refresHandler() {
            this.refreshMenuTree();
            this.queryTable();
        },
        refreshMenuTree() {
            this.$refs["menu-tree"].refreshMenus();
        },
        queryTable() {
            this.$refs["menuTable"].doQuery();
        },
        resetForm() {
            this.$refs["menuForm"].resetFields();
        },
        handlerSelectMenuTree(menu) {
            this.form.parent_id = menu.id;
            this.queryTable();
        },
        showMenuDialog(type, data) {
            this.dialog.menuInfo.visible = true;
            this.dialog.menuInfo.type = type;
            this.dialog.menuInfo.data = data;
            this.dialog.menuInfo.title =
                type === "add"
                    ? "新增菜单"
                    : type === "update"
                    ? "编辑菜单"
                    : "菜单详情";
        },
        deleteMenu(data) {
            this.$confirm(
                `此操作将永久删除该菜单（菜单名称：${data.menu_name}）, 是否继续?`,
                "提示",
                {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }
            )
                .then(async () => {
                    await this.callDeleteMenuApi(data);
                    this.refreshMenuTree();
                })
                .catch(() => {})
                .finally(() => {});
        }
    }
};
</script>
<style lang="scss" scoped>
.view-menu {
    position: absolute;
    height: 100%;
    width: 100%;
    .view-menu-aside {
        .menu-tree {
            height: calc(100% - 2px);
        }
    }
    .view-menu-main {
        display: flex;
        flex-direction: column;
        padding: 0;
        margin-left: 20px;
        .button-ctrl {
            margin-bottom: 10px;
        }
        .menu-table {
            flex: 1;
            .column-scope-action {
                > * + * {
                    margin-left: 10px;
                }
            }
        }
    }
}
</style>
