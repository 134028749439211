export default [
    {
        prop: "menu",
        label: "菜单标识",
        minWidth: 150
    },
    {
        prop: "menu_name",
        label: "菜单名称",
        minWidth: 255
    },
    {
        prop: "icon",
        label: "图标",
        minWidth: 60
    },
    {
        prop: "type",
        label: "类型",
        minWidth: 80
    },
    {
        prop: "sort",
        label: "排序",
        minWidth: 60
    },
    {
        prop: "path",
        label: "地址",
        minWidth: 255
    },
    {
        prop: "component",
        label: "组件",
        minWidth: 350
    },
    {
        prop: "action",
        label: "操作",
        fixed: "right",
        width: 270
    }
];
